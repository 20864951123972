export function downloadFile({
  data,
  type,
  name = '',
}: {
  data: string | Blob;
  type?: string;
  name: string;
}) {
  const blob = new Blob([data], { type });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  if (name) {
    link.download = name;
  }
  link.click();
  setTimeout(() => URL.revokeObjectURL(link.href), 10000);
}

export function getFilenameFromContentDisposition(
  contentDisposition: Nullable<string>,
) {
  const disposition = contentDisposition || '';
  if (disposition) {
    const filename = disposition.split(';')[1];
    const value = filename.includes("filename*=UTF-8''")
      ? filename.replace("filename*=UTF-8''", '').trim()
      : filename.replace('filename=', '').replaceAll('"', '').trim();
    return decodeURI(value);
  }
  return '';
}
