export const letterCodes = {
  BY: 'BY',
  RU: 'RU',
  KZ: 'KZ',
  UA: 'UA',
};

export const regionCodes = {
  OTHER: '000',
};
