import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  Credentials,
  LoginResponse,
  UserDivision,
  UserPersonalData,
} from '../../types/auth';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly USER = `${environment.apiUrl}/auth/user`;
  readonly DIVISION = `${environment.apiUrl}/auth/division`;
  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  login(credentials: Credentials) {
    return this.http.post<LoginResponse>(`${this.USER}/signIn`, {
      email: credentials.login,
      password: credentials.password,
    });
  }

  getUserPersonalData() {
    return this.http.get<UserPersonalData>(`${this.USER}/getUserPersonalData`);
  }

  saveUserPersonalData(payload: UserPersonalData) {
    return this.http.post(`${this.USER}/saveUserPersonalData`, payload);
  }

  getActiveUserByDivisionId(divisionId: number) {
    return this.http.get<UserPersonalData[]>(
      `${this.USER}/getActiveUsersByDivisionId?divisionId=${divisionId}`,
    );
  }

  getUserDivisions() {
    return this.http.get<UserDivision[]>(`${this.DIVISION}/getByUserId`);
  }

  changeActiveDivision(id: number) {
    return this.http.post(
      `${this.USER}/changeActiveDivisionId?divisionId=${id}`,
      null,
    );
  }
}
